'use client';

import localServerApi from '@/api/localServer';

import ErrorContainer from '@/components/error-handling/top-level/ErrorContainer';

import useMount from '@/hooks/useMount';

export default function Error({ error }) {
  useMount(() => {
    localServerApi.global.logError({
      ...error,
      message: error?.message,
      digest: error?.digest,
    });

    console.error('Top level error: ', error);
  });

  const reset = () => {
    window.location.replace('/');
  };

  return <ErrorContainer error={error} reset={reset} />;
}
