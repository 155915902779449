import styled from 'styled-components';

import mq from '@/styles/js/utils/mq';

export const ErrorPage = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem;
`;

export const ErrorPageContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  > * + * {
    margin-top: 2rem;

    ${mq('medium')} {
      margin-top: 4rem;
    }
  }
`;

