import Icon from '@/components/graphics/Icon';
import Mq from '@/components/helpers/Mq';

import route from '@/lib/routes';

import * as Styled from './index.styles';

/**
 * App-wide footer for larger devices.
 */
const FooterLarge = ({ className }) => (
  <Mq min="medium">
    <Styled.FooterLarge className={className}>
      <Styled.CopyRight>
        © {new Date().getFullYear()} Deskpass Corporation. All Rights Reserved.
      </Styled.CopyRight>

      <Styled.Links>
        <Styled.ExternalLink href={route('about')}>About</Styled.ExternalLink>
        <Styled.ExternalLink href={route('blog')}> Blog</Styled.ExternalLink>
        <Styled.ExternalLink href={route('tos')}>Terms</Styled.ExternalLink>
        <Styled.ExternalLink href={route('privacyPolicy')}>
          Privacy
        </Styled.ExternalLink>
        <Styled.Link href={route('rules')}>Things to Know</Styled.Link>
      </Styled.Links>

      <Styled.Socials>
        <Styled.ExternalLink href={route('facebook')}>
          <Icon type="facebook-24" />
        </Styled.ExternalLink>
        <Styled.ExternalLink href={route('twitter')}>
          <Icon type="twitter-24" />
        </Styled.ExternalLink>
        <Styled.ExternalLink href={route('instagram')}>
          <Icon type="instagram-24" />
        </Styled.ExternalLink>
      </Styled.Socials>
    </Styled.FooterLarge>
  </Mq>
);

export default FooterLarge;
